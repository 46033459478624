<template>
  <div
    class="bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/bg-banner.jpg')"
  >
    <!-- header  -->
    <div class="relative z-10">
      <div class="container">
        <TopHeader />
      </div>
    </div>
    <div class="py-52">
      <div class="container">
        <div class="md:w-2/3 lg:w-1/2">
          <div class="px-4 py-8 text-white bg-black bg-opacity-80">
            <p class="text-lg font-semibold">Discover our range of</p>
            <h1 class="py-2 text-4xl">Curtains and Blinds</h1>
            <p class="pt-4 font-medium leading-tight">
              We can <span class="text-yellow-300">custom made</span> based on
              your requirements!
            </p>

            <div v-if="!hideEnquiry" class="mt-3">
              <p
                class="w-48 py-1 text-center transition duration-300 transform bg-red-500 rounded-md hover:scale-110"
              >
                <a href="https://wa.me/60133934134">Get Your Free Quote</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader.vue";

export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TopHeader,
  },
};
</script>
